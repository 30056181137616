<template>
  <div class="my-5">
    <div class="my-2 ticketing-card-subtitle">
      <b>{{ $t('Ticketing.priority') }}</b>
    </div>
    <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="evasion_limit"
      :disabled="locationItems.length < 1"
      persistent
      width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="evasionLimitText"
          :label="`${$t('Ticketing.prioritySelect')}${required ? '*' : ''}`"
          :disabled="locationItems.length < 1"
          prepend-icon="mdi-calendar-cursor"
          readonly
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="evasion_limit"
        :picker-date.sync="pickerDate"
        :allowed-dates="filterAllowedDates"
        :title-date-format="d => moment(d).format('DD-MM-YYYY')"
        :locale="app_user.app_user_data[0].language"
        first-day-of-week="1"
        scrollable
        :min="today"
        :max="nextYear"
        :events="d => moment(d).isAfter(today, 'day') && moment(d).isSameOrBefore(nextYear, 'day')"
        :event-color="d => filterAllowedDates(d) ? '#0ca344' : '#EF5350'"
      >
        <v-btn
          text
          color="red"
          @click="cancel()"
        >
          {{ $t('Btn.cancel') }}
        </v-btn>
        <v-spacer />
        <!-- <v-btn
          text
          color="primary"
          @click="clear()"
        >
          {{ $t('Btn.clear') }}
        </v-btn> -->
        <v-btn
          text
          color="#0da344"
          @click="save()"
        >
          {{ $t('Btn.ok') }}
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment-business-days';

export default {
  components: {},
  props: {
    locationItems: {
      type: Array,
      default: () => []
    },
    isProvinciale: {
      type: Boolean,
      default: false
    },
    evasionLimit: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    concurrentRequestCount: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      modal: false,
      today: moment().format('YYYY-MM-DD'),
      nextYear: moment().add(1, 'years').format('YYYY-MM-DD'),
      pickerDate: null,
      evasion_limit: this.evasionLimit,
      freeSlotDays: [],
      businessDays: []
    };
  },
  computed: {
    ...mapState(['ticketing', 'policy', 'map', 'app_user']),
    evasionLimitText() {
      return this.evasion_limit !== '' ? `${moment(this.evasion_limit).format('DD-MM-YYYY')}` : '';
    }
  },
  watch: {
    $route() {},
    pickerDate(val) {
      this.checkBusinessDays(val);
      this.checkSlots();
    },
    async modal(val) {
      if (val) {
        await this.getAllSlots();
        this.checkSlots();
      } else {
        this.pickerDate = this.evasion_limit.substring(0, 7) || this.today.substring(0, 7);
      }
    },
    evasionLimit(val) {
      if (val === '') {
        this.evasion_limit = this.evasionLimit;
      }
    },
    isProvinciale: {
      async handler() {
        this.pickerDate = this.today.substring(0, 7);
        this.checkSlots();
      },
      immediate: true
    }
  },
  async mounted() {
    await this.getAllSlots();
  },
  methods: {
    ...mapActions(['storeListEvasionLimitSlots']),
    save() {
      this.$refs.dialog.save(this.evasion_limit);
      const prev3Slots = [...Array(3)].map((e, i) => {
        return moment(this.evasion_limit, 'YYYY-MM-DD').businessSubtract(i + 1).format('YYYY-MM-DD');
      });
      const filteredSlots = this.ticketing.evasionLimitSlots.filter(d => {
        return moment(d.evasion_limit).isSameOrAfter(this.firstAllowedDate(), 'day') && prev3Slots.includes(d.evasion_limit);
      });
      const canBeMoved = filteredSlots.length === 0 || filteredSlots.some(d => d.max_slots - d.total_slots > 0);
      this.$emit('can-be-moved', canBeMoved);
      this.$emit('update-evasion-limit', this.evasion_limit);
    },
    cancel() {
      this.evasion_limit = this.evasionLimit;
      this.$refs.dialog.save(this.evasionLimit);
      this.$emit('update-evasion-limit', this.evasionLimit);
      this.modal = false;
    },
    clear() {
      this.evasion_limit = '';
    },
    async getAllSlots() {
      const datesToCheck = [...Array(2)].map((e, i) => moment().add(i, 'years').format('YYYY-'));
      await this.storeListEvasionLimitSlots(datesToCheck);
    },
    filterAllowedDates(date) {
      return this.freeSlotDays.includes(date) && moment(date).isSameOrAfter(this.firstAllowedDate(), 'day');
    },
    firstAllowedDate() {
      const pointEndDateIsNear = this.locationItems.some(location => moment(location.end_date).isSame(this.today, 'day'));
      const workingDays = this.isProvinciale ? 5 : 2;

      const calcWorkingDays = this.isProvinciale || pointEndDateIsNear
        ? moment(this.today, 'YYYY-MM-DD').add(workingDays, 'days')
        : moment(this.today, 'YYYY-MM-DD').nextBusinessDay();

      return moment(calcWorkingDays).format('YYYY-MM-DD');
    },
    checkBusinessDays(date) {
      const [year, month] = date.split('-');
      const monthBusinessDays = moment(`${year}-${month}-01`, 'YYYY-MM-DD').monthBusinessDays();
      this.businessDays = monthBusinessDays.map(day => moment(day).format('YYYY-MM-DD'));
    },
    checkSlots() {
      const fullSlotDays = this.isProvinciale
        ? this.ticketing.evasionLimitSlots.filter(d => {
          const prov_slots = d.max_slots > 2 ? 2 : d.max_slots;

          return d.total_slots >= d.max_slots || d.is_prov >= prov_slots;
        }).map(d => d.evasion_limit)
        : this.ticketing.evasionLimitSlots.filter(d => {
          return this.concurrentRequestCount >= d.max_slots - d.total_slots;
          // return d.total_slots >= d.max_slots;
        }).map(d => d.evasion_limit);

      this.freeSlotDays = this.businessDays.filter(d => !fullSlotDays.includes(d));
    }
  }
};
</script>

<style scoped>
.ticketing-card-subtitle {
  font-size: 18px;
}
</style>
